/* overrides for the DevExpress Datebox*/
.dx-texteditor.dx-state-hover {
    background-color: inherit;
}

.dx-texteditor.dx-editor-outlined {
    border-radius: 0px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08);
}

.dx-overlay-wrapper {
    z-index: 9999 !important;
}
