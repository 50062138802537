.icon {
    fill: currentColor;

    @mixin size($size) {
        min-width: $size;
        height: $size;
        width: $size;
    }

    &.icon-sm {
        @include size(16px);
    }

    &.icon-md {
        @include size(24px);
    }

    &.icon-lg {
        @include size(32px);
    }
}
