/* Core variables */
@import "climb-variables";
@import "bootstrap/variables-custom";
@import "~bootstrap/scss/mixins/breakpoints";

/* Overrides for ng-bootstrap Bootstrap v4 components */
.app {
    code {
        padding: 2px 4px;
        background-color: #f9f2f4;
        border-radius: 0;
    }

    legend {
        font-size: 1rem;
        border-bottom: 1px solid #e5e5e5;
    }

    @include media-breakpoint-up(sm) {
        .form-group.row .col-form-label {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .table th {
        border-top: 0;
    }

    .well {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 0;
    }

    .well-sm {
        padding: 9px;
    }

    /* Toaster.js styles are overwritten by Bootstrap*/
    .toast {
        background-color: #030303;
        border-color: #030303;
    }
    .toast-success {
        background-color: #51A351;
        border-color: #51A351;
    }
    .toast-error {
        background-color: #BD362F;
        border-color: #BD362F;
    }
    .toast-info {
        background-color: #2F96B4;
        border-color: #2F96B4;
    }
    .toast-warning {
        background-color: #F89406;
        border-color: #F89406;
    }

    /* Badges */
    .badge-secondary {
        font-size: 9.75px;
        color: #fff;
        background-color: #999;
    }

    /* Modals */
    .modal-dialog {
        &.modal-xl {
            width: fit-content !important;
            max-width: 100vw;

            .modal-content {
                width: fit-content !important;
                min-width: calc(100vw - 20px);
                max-width: 100vw;

                @include media-breakpoint-up(sm) {
                    min-width: 80vw;
                }
                @include media-breakpoint-up(md) {
                    min-width: 768px;
                }
            }
        }
    }

    .modal-body-tall {
        overflow-y: auto;
        height: 70vh !important;
    }

    .modal-header {
        background-color: $gray-depth;
        color: #fff;
        height: 40px;
        justify-content: center;

        .close {
            margin-top: -6px;
            margin-right: -4px;
            color: $body-bg;
        }

        & > h3,
        & > h4 {
            color: #fff;
            font-size: 18px;
        }
    }
/* ng-bootstrap datepicker has `background: transparent` we must override */
.app climb-ngb-date .bg-primary {
    background-color: $brand-primary !important;
}
.app ngb-datepicker .bg-primary {
    background-color: $brand-primary !important;
}
.ngb-dp-body {
    z-index: 2002 !important;
}
/* Override ng-bootstrap datepicker <select> styles */
.app .climb-ngb-date select {
    padding: 1px;
    font-size: $font-size-base;
    line-height: $line-height-base;
    height: $line-height-base;
}
.app ngb-datepicker select {
    padding: 1px;
    font-size: $font-size-base;
    line-height: $line-height-base;
    height: $line-height-base;
}

    .modal-header-warning {
        background-color: $brand-warning;
    }

    .modal-body > p {
        font-size: 14px;
    }

    .modal-backdrop {
        opacity: 0.5 !important;
    }

    .modal-footer {
        width: 100%;
        white-space: nowrap;

        .btn-set + .btn-set {
            margin-left: 20px;
        }

        div {
            display: inline-block;
        }
    }

    .popover-content .modal-footer {
        padding-left: 0;
        padding-right: 0;
    }

    .dropdown-menu {
        max-width: 600px;
        min-width: 150px;  
        a, p, li {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; 
        }
        &.scrollable.show {
            max-height: 300px;
            overflow-y: auto;
        }
    }
    .dropdown-item {
        font-size: 12px;
        white-space: unset;

        // override styles for options with parent as a label
        span[role="menuitem"] {
            padding-left: 0px !important;
        }
        span[role="menuitem"] > span {
            width: auto !important;
            display: unset !important;
        }
    }

    .input-group-md {
        & > .form-control,
        & > .custom-select,
        & > .input-group-prepend > .input-group-text,
        & > .input-group-append > .input-group-text,
        & > .input-group-prepend > .btn,
        & > .input-group-append > .btn {
            padding: 5px 8px;
            border-radius: 0;
        }

        & > .form-control:not(textarea),
        & > .custom-select {
            height: 30px;
        }
    }

    .flex-gap-1 > *:not(:last-child) {
        margin-right: toRem(4px);
    }

    .nav-tabs {
        padding: 6px 3px 0;

        .nav-link.active {
            cursor: default;
        }
    }

    /* Tooltip */
    .tooltip-inner {
        text-align: left;
    }
}
