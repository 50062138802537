// Color grouped into 4 main categories:
// - Text & icons
// - Surfaces. For page, modal, backgrounds
// - Elements. For elements on surfaces mainly for interactive elements
// - Lines

:root {
    --text-neutral-primary: #0F1011;
    --text-neutral-primary-inverse: #FFFFFF;
    --text-neutral-secondary: rgba(15, 16, 17, 0.6);
    --text-neutral-secondary-inverse: rgba(255, 255, 255, 0.64);
    --text-neutral-tertiary: rgba(15, 16, 17, 0.24);
    --text-neutral-tertiary-inverse: rgba(255, 255, 255, 0.4);

    --text-accent-primary: #3F6AC6;
    --text-accent-primary-hover: #4677DE;
    --text-accent-primary-inverse: #3F6AC6;
    --text-accent-primary-hover-inverse: #7393FF;

    --text-positive-primary: #228530;
    --text-positive-primary-inverse: #44BB54;

    --text-negative-primary: #D13400;
    --text-negative-primary-inverse: #FF7E53;

    --text-warning-primary: #8F6900;
    --text-warning-primary-inverse: #F3B63B;

    --text-info-primary: #0F101199;
    
    --surface-neutral-primary: #FFFFFF;
    
    --surface-secondary: #F4F4F4;

    --surface-accent-primary: #182341;
    --surface-accent-secondary: #F5F5F5;
    --surface-accent-tertiary: #ECECEC;

    --element-neutral-primary: rgba(15, 16, 17, 0.04);
    --element-neutral-primary-hover: rgba(15, 16, 17, 0.08);
    --element-neutral-primary-inverse: rgba(255, 255, 255, 0.1);
    --element-neutral-primary-hover-inverse: rgba(255, 255, 255, 0.14);

    --element-neutral-secondary: rgba(15, 16, 17, 0.12);
    --element-neutral-secondary-hover: rgba(15, 16, 17, 0.16);

    --element-accent-secondary: rgba(70, 119, 222, 0.16);
    --element-accent-secondary-inverse: rgba(133, 162, 222, 0.32);

    --element-accent-tertiary: rgba(70, 119, 222, 0.08);
    --element-accent-tertiary-inverse: rgba(133, 162, 222, 0.24);

    --element-positive-primary: #228530;
    --element-positive-primary-hover: #289937;
    --element-positive-secondary: rgba(34, 133, 48, 0.12);
    --element-positive-primary-inverse: #038127;
    --element-positive-primary-hover-inverse: #049B2F;
    --element-positive-secondary-inverse: rgba(66, 169, 80, 0.2);

    --element-negative-primary: #D13400;
    --element-negative-primary-hover: #D14A1D;
    --element-negative-primary-inverse: #FF7042;
    --element-negative-primary-hover-inverse: #F26436;
    --element-negative-secondary: rgba(255, 91, 20, 0.08);
    --element-negative-secondary-inverse: rgba(255, 112, 66, 0.2);

    --element-warning-primary: #F2B000;
    --element-warning-primary-hover: #F2B91F;
    --element-warning-secondary: rgba(247, 180, 0, 0.16);
    --element-warning-primary-inverse: #F3B63B;
    --element-warning-primary-hover-inverse: #F7BC45;
    --element-warning-secondary-inverse: rgba(243, 182, 59, 0.32);

    --element-info-secondary: rgba(15, 16, 17, 0.04);

    --element-disable: repeating-linear-gradient(
            -45deg,
            transparent 0 2.84px,
            rgba(15, 16, 17, 0.08) 2.84px 4.24px
    );
    --element-disable-inverse: repeating-linear-gradient(
            -45deg,
            transparent 0 2.84px,
            rgba(255, 255, 255, 0.12) 2.84px 4.24px
    );

    --line-neutral-primary: rgba(15, 16, 17, 0.08);
    --line-neutral-primary-hover: rgba(15, 16, 17, 0.12);
    --line-neutral-secondary: rgba(15, 16, 17, 0.24);
    --line-neutral-tertiary: rgba(15, 16, 17, 0.48);
    --line-neutral-primary-inverse: rgba(255, 255, 255, 0.12);
    --line-neutral-primary-hover-inverse: rgba(255, 255, 255, 0.18);
    --line-neutral-secondary-inverse: rgba(255, 255, 255, 0.24);
    --line-neutral-tertiary-inverse: rgba(255, 255, 255, 0.48);

    --line-accent-primary: #4677DE;
    --line-accent-primary-inverse: #4677DE;

    --line-positive-primary: #289937;
    --line-positive-primary-inverse: #42A950;

    --line-negative-primary: #D13400;
    --line-negative-primary-inverse: #FF7042;

    --line-warning-primary: #F2B000;
    --line-warning-primary-inverse: #F3B63B;

    --line-info-primary: #0F10117A;
    
    --logo-primary: #7FB867;
    --logo-primary-hover: #A5E589;
    --logo-primary-inverse: #98D37E;
}
