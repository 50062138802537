/*!
 * CLIMB Bootstrap customizations
 */

// Core variables and mixins
@import "variables-custom";
@import "~bootstrap/scss/bootstrap";

.btn-primary {
    @include button-variant($btn-primary-bg, $btn-primary-border, darken($btn-primary-bg, 10%));
    color: $btn-primary-color;

    &[disabled],
    &.disabled {
        color: $btn-primary-color;
    }
}
.btn-secondary {
    @include button-variant($btn-default-bg, $btn-default-border, darken($btn-default-bg, 10%));
    color: $btn-default-color;

    &[disabled],
    &.disabled {
        color: $btn-default-color;
    }
}
.btn-success {
    @include button-variant($btn-success-bg, $btn-success-border, darken($btn-success-bg, 10%));
    color: $btn-success-color;

    &[disabled],
    &.disabled {
        color: $btn-success-color;
    }
}
.btn-info {
    @include button-variant($btn-info-bg, $btn-info-border, darken($btn-info-bg, 10%));
    color: $btn-info-color;

    &[disabled],
    &.disabled {
        color: $btn-info-color;
    }
}
.btn-warning {
    @include button-variant($btn-warning-bg, $btn-warning-border, darken($btn-warning-bg, 10%));
    color: $btn-warning-color;

    &[disabled],
    &.disabled {
        color: $btn-warning-color;
    }
}
.btn-danger {
    @include button-variant($btn-danger-bg, $btn-danger-border, darken($btn-danger-bg, 10%));
    color: $btn-danger-color;

    &[disabled],
    &.disabled {
        color: $btn-danger-color;
    }
}
