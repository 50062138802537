﻿// Climb style variables

//== Colors
$gray-depth: #495461;
$gray-depth-light: #5f6d7e;
$blue-surf: #aad3e2;
$gray-blue: #8996A4;

$selected-item-bg: lighten($blue-surf, 15%);

//== Sizing
$facet-selector-width: 156px;
$footer-height: 30px;
